<template>
    <div class="container">
        <div class="row project">
            <div class="col-md-4">
                <h2>Oriental Bay Tugboat</h2>
                <p> Structural design of the link to the tugboat.</p>
            </div>
            <div class="col-md-8">
                <img class="img-responsive spacer" src="images/projects/tugboat-1.jpg" alt="Tug Boat"/>
                <img class="img-responsive" src="images/projects/tugboat-2.jpg" alt="Tug Boat"/>
            </div>
        </div>

        <div class="row project">
            <div class="col-md-4">
                <h2>Innovative Structural System</h2>
                <p>Engineering design of unusual roof form.</p>
            </div>
            <div class="col-md-8">
                <img class="img-responsive spacer" src="images/projects/innovative-1.jpg" alt="Roof form exterior"/>
                <img class="img-responsive" src="images/projects/innovative-2.jpg" alt="Roof form interior"/>
            </div>
        </div>

        <div class="row project">
            <div class="col-md-4">
                <h2>Cuba Street</h2>
                <p>Strengthening and modernising the existing building.</p>
            </div>
            <div class="col-md-8">
                <img class="img-responsive" src="images/projects/ombra.jpg" alt="Cuba Street building"/>
            </div>
        </div>

        <div class="row project">
            <div class="col-md-4">
                <h2>Performing Arts: Military Tattoo</h2>
                <p>Engineering design of all the scenery and prop structures for the Royal Edinburgh Military Tattoo, held at Wellington Stadium.</p>
            </div>
            <div class="col-md-8">
                <img class="img-responsive" src="images/projects/edinburugh-1.jpg" alt="Military Tattoo"/>
            </div>
        </div>
    </div>
</template>