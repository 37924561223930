<template>
    <div class="container">
        <div class="row project">
            <div class="col-md-4">
                <h2>Torrens Tce Apartment Complex</h2>
                <p>Structural design for new apartment complex.</p>
            </div>
            <div class="col-md-8">
                <gallery :images="[
                    {src: 'images/projects/apartment-1.jpg', title: 'Torrens Tce Apartment Complex'},
                    {src: 'images/projects/apartment-2.jpg', title: 'Torrens Tce Apartment Complex'},
                    {src: 'images/projects/apartment-3.jpg', title: 'Torrens Tce Apartment Complex'},
                    {src: 'images/projects/apartment-4.jpg', title: 'Torrens Tce Apartment Complex'},
                ]"/>
            </div>
        </div>
        <div class="row project">
            <div class="col-md-4">
                <h2>Dixon Lane Apartments</h2>
                <p>This apartment block was subject to a Leaky Building claim.</p>
                <p> As part of the refurbishment, the upper two levels and deck areas were completely stripped and re-clad. Low maintenance materials were selected, that provided a clean, modern appearance.</p>
            </div>
            <div class="col-md-8">
                <img class="img-responsive" src="images/projects/dixon-lane-4.jpg" alt="Dixon Lane Apartments"/>
            </div>
        </div>
        <div class="row project">
            <div class="col-md-4">
                <h2>Metro Apartments</h2>
                <p>This apartment block was subjected to a Weathertight Homes Resolution Service (WHRS) Claim. The remediation work was staged over a number of years which involved reconstructing the inset decks, re-roofing and repairing facades, and reconstructing the balcony areas on level three.</p>
            </div>
            <div class="col-md-8">
                <img class="img-responsive" src="images/projects/metro.jpg" alt="Metro Apartments"/>
            </div>
        </div>
    </div>
</template>

<script>
import Gallery from '../components/Gallery.vue';

export default {
    components: {
        Gallery,
    },
};
</script>