<template>
    <div class="container">
        <div class="row project">
            <div class="col-md-4">
                <h2>Burger King</h2>
                <p>Architectural and structural design for the new fast food outlet in Lower Hutt, including drive through.</p>
            </div>
            <div class="col-md-8">
                <img class="img-responsive spacer" src="images/projects/burger-king.jpg" alt="Burger King"/>
                <img class="img-responsive" src="images/projects/burger-king-2.jpg" alt="Burger King 2"/>
            </div>
        </div>
        <div class="row project">
            <div class="col-md-4">
                <h2>Target</h2>
                <p>A new bulk retail/office complex was built on the former Todd Park car park site. A number of retail tenancies were created, in addition to a separately tenanted office floor on the north side.</p>
            </div>
            <div class="col-md-8">
                <img class="img-responsive spacer" src="images/projects/target.jpg" alt="Target"/>
                <img class="img-responsive" src="images/projects/target-2.jpg" alt="Target 2"/>
            </div>
        </div>
        <div class="row project">
            <div class="col-md-4">
                <h2>Smiths City</h2>
                <p>The existing car dealership was refurbished and extended to accommodate a new retail tenant.</p>
                <p>A high bay warehouse was added to the rear, and the facade refurbished to reflect the new use. The building was also seismically strengthened.</p>
            </div>
            <div class="col-md-8">
                <img class="img-responsive" src="images/projects/smithcity.jpg" alt="SmithsCity"/>
            </div>
        </div>
        <div class="row project">
            <div class="col-md-4">
                <h2>Homeplus</h2>
                <p>An existing warehouse was refurbished to accommodate the Homeplus offices, showroom and manufacturing facilities.</p>
                <p>The facade was refurbished and modernised to reflect the Homeplus brand.</p>
            </div>
            <div class="col-md-8">
                <img class="img-responsive" src="images/projects/homeplus.jpg" alt="Homeplus"/>
            </div>
        </div>
    </div>
</template>