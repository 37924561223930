<template>
    <div class="container">
        <div class="row project">
            <div class="col-md-4">
                <h2>Pacific Radiology - Porirua</h2>
                <p>Refurbishment of an existing tenancy for Pacific Radiology encompassing an enlarged floor area.</p>
                <p>The interior was totally stripped back and refurbished to accommodate a new reception area and specialist imaging rooms. The facade was modernised at street level.</p>
            </div>
            <div class="col-md-8">
                <img class="img-responsive" src="images/projects/porirua.jpg" alt="Pacific Radiology Porirua"/>
            </div>
        </div>

        <div class="row project">
            <div class="col-md-4">
                <h2>Pacific Radiology - Wellington</h2>
                <p>Fit-out of two floors to accommodate the Wellington branch of Pacific Radiology.</p>
                <p>Work on Level 1 included new reception, waiting and public areas, specialist imaging rooms, call centre for bookings and support and staff facilities.</p>
                <p>On Level 10 floor we designed new office spaces and support facilities for management staff, and a new conference room.</p>
            </div>
            <div class="col-md-8">
                <gallery :images="[
                    {src: 'images/projects/lambton-quay.jpg', title: 'Pacific Radiology Lambton Quay Reception Area'},
                    {src: 'images/projects/lq-2.jpg', title: 'Pacific Radiology Lambton Quay corridor'},
                    {src: 'images/projects/lq-3.jpg', title: 'Pacific Radiology Lambton Quay corridor 2'},
                    {src: 'images/projects/lq-4.jpg', title: 'Pacific Radiology Lambton Quay room'},
                ]"/>
            </div>
        </div>
    </div>
</template>

<script>
import Gallery from '../components/Gallery.vue';

export default {
    components: {
        Gallery,
    },
};
</script>