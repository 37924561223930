<template>
    <div class="row">
        <div v-once v-for="(images, index) in images" :key="index" @click="show(index)" :class="{'col-md-4': index, 'col-sm-4': index, 'col-xs-6': index, 'col-xs-12': index == 0}">
            <img class="img-responsive spacer" :src="images.src" :alt="images.title" :class="{thumbnail: index}"/>
        </div>
    </div>

    <lightbox
        scrollDisabled
        moveDisabled
        :visible="visible"
        :imgs="images"
        :index="index"
        @hide="hide"
    >
        <template v-slot:toolbar=""></template>
    </lightbox>
</template>

<script>
import lightbox from 'vue-easy-lightbox';
import {ref} from 'vue';

export default {
    components: {
        lightbox,
    },
    props: {
        images: Array,
    },
    setup() {
        const index = ref(0);
        const visible = ref(false);
        const show = (newIndex) => {
            index.value = newIndex;
            visible.value = true;
        };
        const hide = () => visible.value = false;
        return {show, hide, visible, index};
    },
};
</script>