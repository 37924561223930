<template>
    <div class="container">
        <div class="row project">

            <div class="col-md-4">
                <h2>The Esplanade - Island Bay</h2>
                <p>The clean and minimalist design uses low maintenance robust materials to withstand the seafront location.</p>
                <p> Large glazing areas were chosen to maximise sea views, and the multiple decking areas for indoor/outdoor flow.</p>
            </div>
            <div class="col-md-8">
                <gallery :images="[
                    {src: 'images/projects/island-bay-1.jpg', title: 'Island Bay'},
                    {src: 'images/projects/island-bay-2.jpg', title: 'Island Bay'},
                    {src: 'images/projects/island-bay-3.jpg', title: 'Island Bay'},
                    {src: 'images/projects/island-bay-4.jpg', title: 'Island Bay'},
                ]"/>
            </div>
        </div>
        <div class="row project">
            <div class="col-md-4">
                <h2>Home Office Complex - Te Horo</h2>
                <p>This home office complex was designed stacked over three levels and nestled amongst the existing trees.</p>
                <p> The materials chosen are low maintenance, and will weather and blend in with the natural setting over time.</p>
            </div>
            <div class="col-md-8">
                <img class="img-responsive" src="images/projects/te-horo-1.jpg" alt="Te Horo"/>
            </div>
        </div>
        <div class="row project">
            <div class="col-md-4">
                <h2>Beattie House</h2>
                <p>This Leaky Home was subject to a Weathertight Homes Resolution Services claim (WHRS). The external envelope of this property was totally refurbished with a new roof, cladding and windows.</p>
            </div>
            <div class="col-md-8">
                <img class="img-responsive" src="images/projects/beattie.jpg" alt="Beattie - after"/>
            </div>
        </div>
        <div class="row project">
            <div class="col-md-4">
                <h2>Trelissick Crescent</h2>
                <p>The existing house was substantially refurbished to provide additional floor area and also modernise the house at the same time.</p>
                <p>A new wing was constructed on the south side of the property, which featured a garage/workshop area on the lower level, and new bedrooms above.</p>
                <p>The kitchen and dining areas were reconstructed, and the existing bedrooms reconfigured. New deck areas were added around the house to maximise outdoor entertaining areas.</p>
            </div>
            <div class="col-md-8">
                <gallery :images="[
                    {src: 'images/projects/trelissick-after-2.jpg', title: 'Trelissick'},
                    {src: 'images/projects/trelissick-before-2.jpg', title: 'Trelissick'},
                    {src: 'images/projects/trelissick-after-1.jpg', title: 'Trelissick'},
                    {src: 'images/projects/trelissick-before-1.jpg', title: 'Trelissick'},
                ]"/>
            </div>
        </div>

        <div class="row project">
            <div class="col-md-4">
                <h2>Clutha Avenue</h2>
                <p>The refurbishments on this house were planned in three stages, over a number of years. The house was modernised, yet still retained the original character.</p>
            </div>
            <div class="col-md-8">
                <img class="img-responsive spacer" src="images/projects/clutha-2.jpg" alt="Clutha"/>
                <img class="img-responsive" src="images/projects/clutha-1.jpg" alt="Clutha"/>
            </div>
        </div>
    </div>
</template>

<script>
import Gallery from '../components/Gallery.vue';

export default {
    components: {
        Gallery,
    },
};
</script>