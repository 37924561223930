<template>
    <div class="container">
        <div class="row project">
            <div class="col-md-4">
                <h2>Akatarawa Crematorium</h2>
                <p>The existing facility was demolished and the new crematorium constructed. We designed a simple, low key structure that does not draw attention to itself.</p>
                <p>The new enlarged building includes a small committal Chapel and associated facilities.</p>
            </div>
            <div class="col-md-8">
                <img class="img-responsive" src="images/projects/akatarawa.jpg" alt="Akatarawa Crematorium"/>
            </div>
        </div>

        <div class="row project">
            <div class="col-md-4">
                <h2>Gee &amp; Hickton</h2>
                <p>The existing funeral home was refurbished and extended to provide a new modern facility. Our work included desiging a new mortuary, extending and refurbishing the Chapel, and creating new public areas and viewing rooms.</p>
            </div>
            <div class="col-md-8">
                <img class="img-responsive spacer" src="images/projects/gee-hickton.jpg" alt="Gee and Hickton"/>
            </div>
        </div>
    </div>
</template>